@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="radio"] + label span {
  transition: background 0.2s, transform 0.2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span {
  transform: scale(1.2);
}

input[type="radio"]:checked + label span {
  background-color: #8884d5;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label {
  color: #8884d5;
}

.custom-parent > :nth-child(5),
.custom-parent > :nth-child(6) {
  @apply col-span-2;
}

.animate-move-x {
  animation: moveBlob 4s infinite;
}

@keyframes moveBlob {
  0% {
    background-position-x: 110%;
    background-position-y: 110%;
  }
  50% {
    background-position-x: 90%;
    background-position-y: 50%;
  }
  100% {
    background-position-x: 110%;
    background-position-y: 110%;
  }
}

.bg-gradient-hover {
  background-size: 200% 200%;
  animation: gradient-x 1s ease infinite;
}

@keyframes gradient-x {
  0%,
  100% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
}

.arrowmove {
  animation: arrowMove 500ms ease infinite;
}

@keyframes arrowMove {
  0%,
  100% {
    margin-left: 10px;
  }
  50% {
    margin-left: 0px;
  }
}

@keyframes rotateClckwisec {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotateIcon {
  animation: rotateClckwisec 1500ms ease forwards infinite;
}

#iconboxesrotate #icon {
  animation: rotateClckwisec 5000ms ease forwards infinite;
}

@keyframes slideInFromTopRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, -100%, 0);
    transform: translate3d(100%, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.elementTopright {
  animation: slideInFromTopRight 1s ease-out infinite;
}

.bodypadding {
  padding-left: 12rem;
  padding-right: 12rem;
}

@media (max-width: 1600px) {
  .bodypadding {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

@media (max-width: 1366px) {
  .bodypadding {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

input.form-control {
  height: auto !important;
  border: 0px !important;
  width: 100% !important;
}

.react-tel-input {
  border-radius: 0.375rem;
  border: 1px solid #e0e0e0;
  padding: 0.5rem 0;
}

#icon-hover-ani {
  animation: hoverAnimation 2s infinite;
}

@keyframes hoverAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
